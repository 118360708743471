const axios = require('axios');

$(document).on('turbolinks:load', function () {
  $('#consultation').toast()
  $('#consultation').on('hidden.bs.toast', function () {
    axios.post('/hide-consultation', {
    })
      .catch(function (error) {
        console.log(error);
      });
  })

  var checkCookies = function () {
    if (Cookies.get('dark-mode')) {
      $('body').addClass("dark-mode");
    }
  }

  var mouseX = 0, mouseY = 0;
  var xp = 0, yp = 0;

  $(document).mousemove(function (e) {
    mouseX = e.pageX - 30;
    mouseY = e.pageY - 30;
  });

  setInterval(function () {
    xp += ((mouseX - xp) / 6);
    yp += ((mouseY - yp) / 6);
    $("#circle").css({ left: xp + 'px', top: yp + 'px' });
  }, 20);


  checkCookies();

  $('.bxslider').bxSlider({
    auto: true,
    autoControls: false,
    stopAutoOnClick: false,
    pager: false,
    controls: false,
    minSlides: 2,
    infiniteLoop: true,
    maxSlides: 2,
    moveSlides: 2,
    slideWidth: 200
  });

  $('.bxslider2').bxSlider({
    slideWidth: 250,
    minSlides: 2,
    maxSlides: 4,
    infiniteLoop: true,
    slideMargin: 10,
    moveSlides: 1,
    pager: false,
    auto: true,
    controls: false
  });

  $("#menu-toggle").on('click', function (e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
  });

  $('#nav-icon').on('click', function () {
    $(this).toggleClass('open');
  });


  $('.dark-mode-btn').on('click', function () {
    if (Cookies.get('dark-mode')) {
      $(".moon").addClass("d-none");
      $(".sun").addClass("d-block");
      $(".moon").removeClass("d-block");
      $('body').removeClass("dark-mode");
      Cookies.remove('dark-mode');

    } else {

      $(".sun").addClass("d-none");
      $(".moon").removeClass("d-none");
      $(".sun").removeClass("d-block");
      $('body').addClass("dark-mode");
      Cookies.set('dark-mode', true);
    }
  });


  $('#to-highlight').click(function () {
    setTimeout(function () {
      $('.contactless').addClass('highlighted');
      setTimeout(function () {
        $('.contactless').removeClass('highlighted');
      }, 4000);
    }, 1900);
  });


  $("#to-highlight").click(function () {
    $('html, body').animate({
      scrollTop: $(".contactless").offset().top - 40
    }, 2000);
  });


})

